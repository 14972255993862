import React from "react"
import TemplatePageEbook  from "@components/pageEbook/template"
import { Helmet } from "react-helmet"
import data from "@components/pageEbook/ebooks/ebook13/dataPageEbook13"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cómo automatizar un ecommerce con chatbots y WhatsApp [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/ebook/automatizar-ecommerce/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo aprovechar el poder de los chatbots y WhatsApp para automatizar tu ecommerce, brindando una experiencia de compra ágil y personalizada."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo automatizar un ecommerce con chatbots y WhatsApp"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/ebook/automatizar-ecommerce/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Ebook/og-imgEbook13.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre cómo aprovechar el poder de los chatbots y WhatsApp para automatizar tu ecommerce, brindando una experiencia de compra ágil y personalizada."
      />
    </Helmet>
    <TemplatePageEbook
      ebook="Ecommerce"
      location={location}
      typeEbook="ebook13"
      clickHereHref="https://beexcc.com/blog/telemarketing/"
      data={data}
    />
  </div>
)

export default IndexPage
